import React, { useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import Img from 'gatsby-image';
import DynamicLink from 'components/DynamicLink.js';

import Layout from './layout.js';

const PhotoboardPage = () => {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <Layout isPhotoboard={true}>
      <div className="photoboard__wrapper">
        <h1 className="visually-hidden">Photo Gallery</h1>
        <DynamicLink className="button-link photoboard__home-btn" to="/">
          Home
        </DynamicLink>
        {!showInfo ? (
          <button
            className="button-link photoboard__info-btn"
            onClick={() => setShowInfo(true)}>
            What's this?
          </button>
        ) : (
          <div className="photoboard__info-modal">
            This is a collection of my travel film photography — feel free to
            drag the photos around! I use a Minolta Maxxum 400si and an Olympus
            Stylus Zoom 140.
            <button
              className="button-link photoboard__info-close-btn"
              onClick={() => setShowInfo(false)}>
              Close
            </button>
          </div>
        )}
        <Photoboard />
      </div>
    </Layout>
  );
};

const Photoboard = () => {
  function calculateX() {
    let max = 500;

    try {
      max =
        window.innerWidth > 150 ? window.innerWidth - 150 : window.innerWidth;
    } catch (e) {
      console.error(e);
    }

    return Math.floor(Math.random() * max);
  }

  function calculateY(numElements) {
    let multiplier = 70;

    try {
      multiplier = window.innerWidth < 450 ? 80 : 100;
    } catch (e) {
      console.error(e);
    }

    return Math.floor(Math.random() * multiplier * numElements);
  }

  function getRandomPos(numElements) {
    return { x: calculateX(), y: calculateY(numElements) };
  }

  // function getMaxWidth() {
  //   let minWidth = 175;

  //   try {
  //     minWidth = window.innerWidth < 450 ? 125 : 175;
  //   } catch (e) {
  //     console.log(e);
  //   }

  //   return Math.floor(Math.random() * 100 + minWidth);
  // }

  return (
    <StaticQuery
      query={graphql`
        {
          arenaChannel(slug: { eq: "snapshots-gro3wnsvb40" }) {
            title
            metadata {
              description
            }
            added_to_at
            children {
              __typename
              ... on ArenaBlock {
                title
                content_html
                description_html
                image {
                  childImageSharp {
                    fluid(maxWidth: 1204) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data =>
        data.arenaChannel.children.map((item, index) => (
          <ContentBlock
            key={`mood-${index}`}
            item={item}
            randomPos={getRandomPos(data.arenaChannel.children.length)}
          />
        ))
      }
    />
  );
};

const ContentBlock = ({ item: { image, description_html }, randomPos }) => {
  const [dragging, setDragging] = useState(false);
  const [tooltipX, setTooltipX] = useState(0);
  const [tooltipY, setTooltipY] = useState(0);

  const displayTooltip = (e, data) => {
    const { x, y } = data.node.getBoundingClientRect();

    setTooltipX(e.clientX - x + 10);
    setTooltipY(e.clientY - y + 10);
    setDragging(true);
  };

  const hideTooltip = () => {
    setDragging(false);
  };

  return (
    image && (
      <Draggable
        defaultPosition={randomPos}
        onDrag={displayTooltip}
        onStop={hideTooltip}>
        <div>
          <Img
            style={{ position: 'absolute' }}
            className="photoboard__img"
            draggable={false}
            fluid={image.childImageSharp.fluid}
          />
          {description_html && (
            <div
              className={
                dragging
                  ? 'photoboard__img-tooltip dragging'
                  : 'photoboard__img-tooltip'
              }
              style={{ top: `${tooltipY}px`, left: `${tooltipX}px` }}
              dangerouslySetInnerHTML={{ __html: description_html }}
            />
          )}
        </div>
      </Draggable>
    )
  );
};

ContentBlock.propTypes = {
  item: PropTypes.object,
  randomPos: PropTypes.object,
};

export default PhotoboardPage;
